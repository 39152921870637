import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { CalendarIcon, Flex, InvertedRoubleIcon, NumberIcon, Spacer, WeightIcon } from 'uikit';
import ru from 'date-fns/locale/ru';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useStore } from 'store/Root';
import { CloseIcon } from 'uikit/Icons/CloseIcon';
import { Select } from 'uikit/Selects';
import { format } from 'date-fns';
import { InquiryHeader } from 'components/Inquiries/pages/InquiryPage';
import { formatDecimals } from 'utils/utils';
import { IOrderDetailsRequest } from 'services/models';

registerLocale('ru', ru);

interface IProps {
  showModalState: boolean;
  handleModalClose: () => void;
  specDate?: string;
  specNumber: number;
  volume: string;
  isTaxable: boolean;
  cultureId: number;
  orderId: string;
  orderPrice: string;
  includeWithdrawals: boolean;
  paymentProcedure: number | null;
  contractNumber?: string;
  profileCompanies?: { value: string; label: string }[];
  buyerCompany: { value: string; label: string };
}

type IDate = Date | null;

export const SpecOrderBaseActionsModal: React.FC<IProps> = observer(
  ({
    showModalState,
    handleModalClose,
    orderId,
    orderPrice,
    isTaxable,
    specDate,
    specNumber,
    volume,
    cultureId,
    includeWithdrawals,
    paymentProcedure,
    contractNumber,
    profileCompanies,
    buyerCompany,
  }) => {
    const { culturesStore, specsOrdersStore } = useStore();

    const selectedProcedure =
      paymentProcedure !== null &&
      specsOrdersStore.paymentProceduresOptions.find((procedure) => procedure.value === paymentProcedure);

    const [selectedSpecDate, setSelectedSpecDate] = useState<IDate>(new Date(specDate as string));
    const [isOrderTaxable, setIsOrderTaxable] = useState<string>(isTaxable ? 'true' : 'false');
    const [activePrice, setActivePrice] = useState<string>(orderPrice);
    const [activeSpecNumber, setActiveSpecNumber] = useState<number>(specNumber);
    const [activeVolume, setActiveVolume] = useState<string>(volume);
    const [activeProfileCompany, setActiveProfileCompany] = useState<{ label: string; value: string }>(buyerCompany);
    const [isWithdrawalsIncluded, setIsWithdrawalsIncluded] = useState<boolean>(includeWithdrawals);
    const [activePaymentProcedure, setActivePaymentProcedure] = useState<number | null>(paymentProcedure || null);
    const [activeContractNumber, setActiveContractNumber] = useState<string>(contractNumber || '');

    const [activeCulture, setActiveCulture] = useState<{
      label: string;
      value: string;
    }>({
      label: 'Культура',
      value: '',
    });

    const handleSpecSave = () => {
      const dataToSend: IOrderDetailsRequest = {
        id: orderId,
        culture: activeCulture.value,
        contractVolume: formatDecimals(activeVolume),
        number: Number(formatDecimals(activeSpecNumber)),
        price: formatDecimals(activePrice),
        specDate: format(selectedSpecDate as Date, 'yyyy-MM-dd'),
        taxable: isOrderTaxable === 'true' ? true : false,
        includeWithdrawals: isWithdrawalsIncluded,
        paymentProcedure: activePaymentProcedure,
        contractNumber: activeContractNumber,
      };
      if (activeProfileCompany.value !== buyerCompany.value) {
        dataToSend['buyerCompany'] = activeProfileCompany.value;
      }
      if (activeCulture.value !== cultureId.toString()) {
        dataToSend['indicators'] = [];
      }

      specsOrdersStore.updateOrderDetails(dataToSend);

      setTimeout(() => {
        handleModalClose();
      }, 1000);
    };

    const handleCultureSelectChange = (culture: { label: string; value: string }) => {
      setActiveCulture(culture);
    };
    const handleProfileCompanySelectChange = (company: { label: string; value: string }) => {
      setActiveProfileCompany(company);
    };

    useEffect(() => {
      if (activeCulture.value === '') {
        const culture = culturesStore.culturesList.find((culture) => culture.value === cultureId.toString());
        setActiveCulture(culture || { label: 'Культура', value: '' });
      }
    }, [culturesStore.culturesList]);

    return (
      <StyledModal
        show={showModalState}
        onHide={handleModalClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeaderWrapper>
          <Modal.Header closeButton />
          <StyledCloseIcon>
            <CloseIcon />
          </StyledCloseIcon>
        </ModalHeaderWrapper>
        <ModalContent>
          <Flex column>
            <InnerHeading>
              <InquiryHeader>Данные спецификации</InquiryHeader>
              <p>Данные по спецификации и продавцу</p>
            </InnerHeading>
            <InnerWrapper>
              <InputsGrid>
                <div>
                  <SubHeader>Номер спецификации</SubHeader>
                  <InputWrapper>
                    <StyledRoubleIcon>
                      <NumberIcon />
                    </StyledRoubleIcon>
                    <StyledInput
                      type="number"
                      name="specNumber"
                      value={activeSpecNumber}
                      onChange={(e) => setActiveSpecNumber(Number(e.target.value))}
                      placeholder="Введите номер спецификации"
                    />
                  </InputWrapper>
                </div>

                <div>
                  <SubHeader>Дата спецификации</SubHeader>
                  <DatePickerWrapper>
                    <StyledRoubleIcon>
                      <CalendarIcon />
                    </StyledRoubleIcon>
                    <DatePicker
                      selected={selectedSpecDate}
                      calendarClassName="shipment-calendar"
                      dateFormat="dd.MM.yyyy"
                      locale="ru"
                      onChange={(date: Date) => setSelectedSpecDate(date)}
                    />
                  </DatePickerWrapper>
                </div>
                <div>
                  <SubHeader>Номер и дата договора </SubHeader>
                  <InputWrapper>
                    <StyledRoubleIcon>
                      <NumberIcon />
                    </StyledRoubleIcon>
                    <StyledInput
                      type="text"
                      name="specContractNumber"
                      value={activeContractNumber}
                      onChange={(e) => setActiveContractNumber(e.target.value)}
                      placeholder="Введите номер и дату договора"
                    />
                  </InputWrapper>
                </div>

                <div>
                  <StyledCultureSelect>
                    <SubHeader>Съёмы</SubHeader>
                    <Select
                      defaultValue={
                        isWithdrawalsIncluded
                          ? { label: 'Считать съёмы', value: 'true' }
                          : { label: 'Не считать съёмы', value: 'false' }
                      }
                      options={[
                        { label: 'Считать съёмы', value: 'true' },
                        { label: 'Не считать съёмы', value: 'false' },
                      ]}
                      onChange={(e) => setIsWithdrawalsIncluded(e.value === 'true' ? true : false)}
                    />
                  </StyledCultureSelect>
                </div>

                <div>
                  <SubHeader>Объём</SubHeader>
                  <InputWrapper>
                    <StyledRoubleIcon>
                      <WeightIcon />
                    </StyledRoubleIcon>
                    <StyledInput
                      type="text"
                      name="volume"
                      value={activeVolume}
                      onChange={(e) => setActiveVolume(e.target.value)}
                      placeholder="Введите объём"
                      pr="61px"
                    />
                    <CurrencyWrapper>тонны</CurrencyWrapper>
                  </InputWrapper>
                </div>

                <StyledCultureSelect>
                  <SubHeader>Культура</SubHeader>
                  <Select
                    placeholder={typeof activeCulture !== 'string' ? activeCulture.label : 'Культура'}
                    options={culturesStore.culturesList}
                    onChange={handleCultureSelectChange}
                  />
                </StyledCultureSelect>

                <div>
                  <SubHeader>Цена (без НДС)</SubHeader>
                  <InputWrapper>
                    <StyledRoubleIcon>
                      <InvertedRoubleIcon />
                    </StyledRoubleIcon>
                    <StyledInput
                      type="text"
                      name="price"
                      value={activePrice}
                      onChange={(e) => setActivePrice(e.target.value)}
                      placeholder="Введите цену"
                      pr="42px"
                    />
                    <CurrencyWrapper>р/т</CurrencyWrapper>
                  </InputWrapper>
                </div>

                <StyledCultureSelect>
                  <SubHeader>Есть ли НДС в сделке</SubHeader>
                  <Select
                    defaultValue={isTaxable ? { label: 'С НДС', value: 'true' } : { label: 'Без НДС', value: 'false' }}
                    options={[
                      { label: 'С НДС', value: 'true' },
                      { label: 'Без НДС', value: 'false' },
                    ]}
                    onChange={(e) => setIsOrderTaxable(e.value)}
                  />
                </StyledCultureSelect>
              </InputsGrid>
              <StyledPaymentSelect>
                <SubHeader>Порядок оплаты</SubHeader>
                <Select
                  defaultValue={
                    selectedProcedure !== false ? selectedProcedure : { label: 'Выберите порядок оплаты', value: '' }
                  }
                  options={specsOrdersStore.paymentProceduresOptions}
                  onChange={(e) => setActivePaymentProcedure(e.value)}
                  menuPlacement="top"
                />
              </StyledPaymentSelect>
              {profileCompanies && profileCompanies.length > 1 && (
                <>
                  <Spacer space={20} />
                  <StyledCultureSelect>
                    <SubHeader>Профили</SubHeader>
                    <Select
                      placeholder={typeof activeProfileCompany !== 'string' ? activeProfileCompany.label : 'Профили'}
                      options={profileCompanies}
                      onChange={handleProfileCompanySelectChange}
                    />
                  </StyledCultureSelect>
                </>
              )}
              <ButtonsWrapper>
                <DiscardChangesButton type="button" onClick={handleModalClose}>
                  Отменить
                </DiscardChangesButton>

                <CounterOfferButton type="button" onClick={handleSpecSave}>
                  Предложить изменения
                </CounterOfferButton>
              </ButtonsWrapper>
              <Spacer space={20} />
            </InnerWrapper>
          </Flex>
        </ModalContent>
      </StyledModal>
    );
  },
);

const InnerHeading = styled.div`
  padding-left: 8px;
`;
const InnerWrapper = styled.div`
  background-color: #f4f6f6;
  display: flex;
  flex-direction: column;
  padding: 16px 8px;
  border-radius: 12px;
`;

export const InputsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
`;

export const StyledModal = styled(Modal)`
  .modal-dialog {
    max-width: 620px;
  }
  .modal-header {
    border: none;
  }

  .modal-content {
    background-color: transparent;
    border: none;
  }
  .btn-close {
    background: none;
    width: 40px;
    height: 40px;
    position: absolute;
    right: -11px;
    top: -8px;
    z-index: 1;
  }
`;

export const ModalHeaderWrapper = styled.div`
  position: relative;
`;

export const SubHeader = styled.div`
  font-weight: 600;
  font-size: 15px;
  line-height: 124%;
  padding-left: 8px;
  color: #292929;
`;
const DatePickerWrapper = styled.div`
  position: relative;
  z-index: 3;
  margin-top: 12px;
  width: 100%;
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__input-container {
    width: 100%;
    input {
      padding: 17px 30px;
      padding-left: 48px;
      border-radius: 12px;
      border: 1px solid #dcdee4;
      width: 100%;
    }
  }
`;

export const StyledCloseIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  cursor: pointer;
`;

export const StyledCultureSelect = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  .Select__control {
    max-width: 100%;
    border: 1px solid #dcdee4;
    max-height: 52px;
    border-radius: 12px;
    background-color: #fff;
  }
  .Select__value-container {
    padding: 10px 16px;
  }
`;
const StyledPaymentSelect = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  .Select__control {
    max-width: 100%;
    border: 1px solid #dcdee4;
    max-height: 52px;
    border-radius: 12px;
    background-color: #fff;
  }
  .Select__value-container {
    padding: 10px 16px;
  }
  .Select__menu {
    max-width: 558px;
  }
`;

export const ModalContent = styled.div`
  padding: 16px 24px;
  background-color: #fff;
  border-radius: 10px;
  max-width: 620px;
`;

interface StyledInputProps {
  pr?: string;
}

const StyledInput = styled.input<StyledInputProps>`
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 16px;
  padding-left: 48px;
  padding-right: ${(props) => props.pr || '16px'};
  width: 100%;
  text-align: left;
  color: #656565;
  font-weight: 400;
  font-size: 15px;
  background-color: #fff;
  border: 1px solid #dcdee4;
  border-radius: 12px;
  &:disabled {
    background-color: #e9ebf1;
    color: #292929;
  }
`;

export const CounterOfferButton = styled.button`
  width: 50%;
  background-color: #8e66fe;
  border-radius: 12px;
  border: 1px solid #825dec;
  padding: 18px 0px;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
`;

export const DiscardChangesButton = styled.button`
  width: 50%;
  background-color: #fff;
  border-radius: 12px;
  border: 1px solid #825dec;
  padding: 18px 0px;
  color: #8e66fe;
  font-weight: 600;
  font-size: 18px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
  margin-top: 16px;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const StyledRoubleIcon = styled.div`
  position: absolute;
  z-index: 1;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  display: grid;
`;
const CurrencyWrapper = styled.div`
  position: absolute;
  z-index: 1;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  display: grid;
  &:before {
    position: absolute;
    top: 50%;
    left: -8px;
    transform: translateY(-50%);

    content: '';
    display: block;
    width: 1px;
    height: 20px;
    background-color: #656565;
  }
`;
